* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;

  &[href^="tel:"] {
    margin-bottom: 4px;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }

  &[href^="mailto:"] {
    margin-bottom: 4px;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
}

input {
  &:focus {
    outline: 0;
  }
}

button {
  background-color: transparent;
  -webkit-appearance: none;
  border: 0;
  padding: 0;

  &:focus {
    outline: 0;
  }
}

.container {
  max-width: 100%;
  margin: auto;
  padding: 0px 16px;
}

@media (min-width: 760px) {
  .container {
    padding: 0px 72px;
  }
}

@media (min-width: 1380px) {
  .container {
    max-width: 1200px;
    padding: 0;
  }
}

.layout {
  position: relative;
  top: 80px;
}
