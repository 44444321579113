nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 64px;
  width: 100%;
  position: fixed;
  color: $grey1;
  background: #fff;
  box-shadow: 0px 4px 12px 4px rgba(26, 22, 44, 0.1);
  z-index: 99;
  transition: 0.3s ease-in-out;
  top: 0;

  @media (max-width: 600px) {
    padding: 16px;
  }

  &.scroll {
    position: absolute;
    color: #fff;
    background: transparent;
    box-shadow: none;

    @media (max-width: 1048px) {
      &-open {
        color: $grey1;
        background: #fff;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    img {
      width: 48px;
      margin-right: 8px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 21px;

      img {
        width: 32px;
        margin-right: 8px;
      }
    }
  }

  .hamburger {
    display: none;
    padding: 2px 8px;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;

    @media (max-width: 1048px) {
      display: block;
    }
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 24px;

    @media (max-width: 1048px) {
      display: none;
      position: absolute;
      width: 100%;
      left: 0;
      background: #fff;
      top: 64px;
      z-index: 9;
      height: 100vh;
      padding-top: 32px;
      padding: 32px 16px;
      border-top: 1px solid #e6e6e6;
      flex-direction: column;

      &.open {
        display: block;
      }
    }

    .link {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      padding: 12px 24px;

      // &:hover {
      //   color: $red;
      // }
    }

    .link.active {
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 10px rgba(33, 68, 87, 0.2);
      border-radius: 8px;
      color: $red;
    }
  }
}

.section-colums {
  background: #333333;
  color: #fff;

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .contact-content {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 3fr 2fr;
    padding: 0px 32px;
    margin-top: 32px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
      margin-top: 0;
      grid-gap: 24px;
    }
  }

  .contact-eng {
    display: flex;
    column-gap: 60px;
    flex-wrap: wrap;

    @media (max-width: 600px) {
      > div {
        margin-bottom: 16px;
      }
    }
  }

  .contact-marketing,
  .contact-eng {
    font-weight: 300;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 21px;
    }

    b {
      margin-bottom: 8px;
    }
  }

  .name {
    margin-bottom: 4px;
    font-weight: 700;
  }
}

.contact {
  padding: 80px 0;
  font-size: 12px;
  @media (max-width: 600px) {
    padding: 32px 0;
  }
}

.footer {
  padding: 40px 0;
  background: #390b0a;
  padding-bottom: 88px;
  font-size: 12px;

  .menu-title {
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  .footer-link {
    line-height: 24px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 21px;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .divider {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .google-maps {
    margin-top: 16px;
    font-weight: 700;

    a {
      text-decoration: underline;
    }
  }
}

.copyright {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 16px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
  }
}

.header {
  margin-top: 80px;
  height: 152px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  overflow: hidden;
  background: #e0e0e0;
  padding-left: 72px;
  background-image: url("../img/gear-1.png");
  background-size: 500px;
  background-repeat: no-repeat;
  background-position-x: 120%;
  background-position-y: 50%;

  span {
    color: $red;
  }

  @media (max-width: 600px) {
    font-size: 20px;
    margin: auto;
    padding: 0;
    text-align: center;
    margin-top: 64px;
    height: 120px;
  }
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  // overflow: hidden;
  // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
}
.tab {
  width: 100%;
  overflow: hidden;
  margin-bottom: 32px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px 6px rgba(26, 22, 44, 0.1);
  border-radius: 8px;

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;

    /* Icon */
    &:hover {
      color: $red;
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: ease-in-out 0.35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 24px;
    background: white;
    transition: ease-in-out 0.35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
}

// :checked
input:checked {
  + .tab-label {
    color: $red;

    // background: darken($red, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100%;
    height: 100%;
    padding: 24px;

    .divider {
      width: 100%;
      border-top: 1px solid #bdbdbd;
      transform: translateY(-24px);
    }
  }
}

.image-card {
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px 6px rgba(26, 22, 44, 0.1);
  border-radius: 6px;
  padding: 24px;
  transition: 0.3s ease-in-out;
  background: #fff;
  z-index: 1;
  text-align: center;

  &.home {
    cursor: pointer;
    &:hover {
      transform: translateY(-16px);
      transition: 0.3s ease-in-out;
    }
  }

  img {
    height: 200px;
    width: 100%;
    object-fit: cover;

    @media (max-width: 870px) {
      height: 300px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 16px;
  }

  &.type-1 {
    img {
      height: 300px;
    }
  }

  &.facilities {
    img {
      height: 450px;
    }
  }
}
