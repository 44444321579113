// Home
.hero {
  background-image: url("../img/hero.png");
  width: 100%;
  height: 90vh;
  max-height: 700px;
}

.brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #fff;

  .title {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 10px;

    @media (max-width: 600px) {
      font-size: 24px;
      margin-bottom: 0;
    }
  }

  .motto {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;

    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  button {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 10px rgba(33, 68, 87, 0.2);
    padding: 12px;
    width: 275px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: $red;
    margin-top: 36px;
    cursor: pointer;
    border: 2px transparent solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    column-gap: 8px;

    &:hover {
      border: 2px $red solid;
    }
  }
}

.section-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #fff;

  &.black {
    color: #000;
  }

  span {
    color: #bf3430;
  }

  @media (max-width: 600px) {
    font-size: 20px;
  }
}

.section-about {
  background: #333333;
  padding: 80px 0;
  color: #fff;

  .about-content {
    display: grid;
    grid-gap: 70px;
    grid-template-columns: 3fr 2fr 3fr;
    font-weight: 300;
    line-height: 21px;
    margin-top: 48px;

    @media (max-width: 600px) {
      font-size: 14px;
    }

    a {
      margin-top: 16px;
      display: block;
      color: #bf3430;
      font-weight: bold;
      text-decoration: underline;

      @media (max-width: 600px) {
        font-size: 18px;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  @media (max-width: 600px) {
    padding: 40px 0;

    .about-content {
      grid-template-columns: 1fr;
    }
  }
}

.section-product {
  overflow-x: hidden;
  padding: 64px 0;
  background: linear-gradient(
    180deg,
    #f2f2f2 79.42%,
    rgba(242, 242, 242, 0) 100%
  );
  height: 100%;
  position: relative;

  @media (max-width: 600px) {
    padding: 40px 0;
  }

  .title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .more-link {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 22px;
      color: $red;
      font-weight: 700;
      column-gap: 8px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .gear-1 {
    position: absolute;
    top: -40px;
    left: -30%;
  }

  .gear-2 {
    position: absolute;
    right: -20%;
    top: 40%;
  }

  .image-group {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    margin-top: 52px;

    @media (max-width: 870px) {
      margin: 52px 64px;
    }

    @media (max-width: 500px) {
      margin: 36px 0px;
    }
  }
}

.section-customer {
  padding: 64px 0;
  background-color: #fff;

  @media (max-width: 600px) {
    padding: 16px 0;
  }

  .customer-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0px 80px;
    margin-top: 52px;

    @media (max-width: 600px) {
      margin: 32px 16px;
    }

    img {
      height: 50px;
      margin-bottom: 32px;

      @media (max-width: 600px) {
        width: 50%;
        object-fit: contain;
      }
    }
  }
}

// Products
.products {
  margin: 80px 0px;

  .type-1-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 600px) {
      grid-gap: 20px;
      grid-template-columns: minmax(1px, 1fr);
    }

    .type-1 {
      img {
        object-fit: contain;
      }
    }
  }

  .type-2-grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: auto auto;
    grid-template-areas:
      "full full"
      "half1 half2"
      "half3 .";

    @media (max-width: 600px) {
      grid-gap: 20px;
      grid-template-columns: minmax(1px, 1fr);
      grid-template-areas:
        "full"
        "half1"
        "half2"
        "half3";
    }

    div {
      img {
        object-fit: contain;
      }
    }

    .item-0 {
      grid-area: full;
      img {
        height: 500px;

        @media (max-width: 600px) {
          height: 300px;
        }
      }
    }

    .item-1 {
      grid-area: half1;
      img {
        height: 300px;
        @media (max-width: 600px) {
          height: 200px;
        }
      }
    }
    .item-2 {
      grid-area: half2;
      img {
        height: 300px;
        @media (max-width: 600px) {
          height: 200px;
        }
      }
    }
    .item-3 {
      grid-area: half3;
      img {
        width: 286px;
        height: 400px;
        object-fit: cover;

        @media (max-width: 600px) {
          width: auto;
        }
      }
    }
  }

  .type-3-grid {
    display: flex;
    margin: 0px -10px;
    flex-wrap: wrap;
    justify-content: center;

    .col {
      padding: 0px 10px;
      width: 40%;
      margin-bottom: 20px;
      img {
        object-fit: contain;
      }
      @media (max-width: 600px) {
        width: 100%;
      }
    }

    .item-0,
    .item-1,
    .item-2,
    .item-3 {
      img {
        height: 500px;
        @media (max-width: 600px) {
          height: 300px;
        }
      }
    }

    .item-4,
    .item-5 {
      img {
        height: 300px;
      }
    }
  }

  .type-4-grid {
    display: flex;
    margin: 0px -20px;
    flex-wrap: wrap;
    justify-content: center;

    .col {
      padding: 0px 20px;
      width: 50%;
      margin-bottom: 40px;
      img {
        height: 350px;
        object-fit: contain;
      }

      @media (max-width: 600px) {
        width: 100%;
      }
    }

    .item-2 {
      width: 100%;
      img {
        height: 400px;
        @media (max-width: 600px) {
          height: 200px;
        }
      }
    }
  }

  .type-5-grid {
    display: flex;
    margin: 0px -20px;
    flex-wrap: wrap;
    justify-content: center;

    .col {
      padding: 0px 20px;
      width: 60%;
      margin-bottom: 40px;
      img {
        height: 800px;
      }

      @media (max-width: 600px) {
        width: 100%;
        img {
          height: 300px;
        }
      }
    }
  }
}

// About
.page-about {
  margin: 88px auto;

  @media (max-width: 600px) {
    font-size: 14px;
  }

  section {
    margin: 0px 40px;
    h3 {
      margin-bottom: 0.5em;
    }
    margin-bottom: 40px;
  }
}
